.d-flex {
  display: flex;
  flex-wrap: wrap;
}
.pristine-error {
  color: red;
  margin-bottom: 15px;
  margin-top: -6px;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-center {
  justify-content: center;
}
.align-items-center {
  justify-content: center;
}
.regular {
  font-weight: normal;
}
/* Header */
.page-header {
  margin-top: 40px;
  margin-bottom: 30px;
}
.brand-logo {
  text-decoration: none;
  color: #f13e45;
}
.avira .brand-logo.avira,
.bullguard .brand-logo.bullguard {
  display: block;
}
.brand-logo.bullguard {
  margin-top: 10px;
}
.brand-logo img {
  display: block;
}
.brand-logo i {
  font-size: 40px;
}


/* Main content */
.page-main-content {
  max-width: 660px;
  margin: 0 auto;
  padding-top: 15px;
  padding-bottom: 30px;
}
.page-main-content header {
  margin: 0 auto;
  padding: 0 30px;
}
.page-main-content header h1 {
  font-size: 28px;
}
.page-main-content header h1 {
  font-size: 28px;
  font-family: "KievitWeb-Book", sans-serif;
  margin: 0;
}
form p {
  margin-top: 20px;
  margin-bottom: 15px;
}

/** Form styles **/
.cancellation {
  margin: 15px auto 0 auto;
  max-width: 650px;
}
.form-header {
  font-size: 18px;
  font-weight: 500;
}
.bg-gray {
  background: #f9f9f9;
  padding: 30px 25px;
  border: 1px solid #e5e6e6;
  border-radius: 4px;
  margin-bottom: 50px;
  position: relative;
}
.form-field.half {
  width: 48.5%;
  margin-bottom: 15px;
  position: relative;
}
.form-field.full {
  width: 100%;
  margin-bottom: 15px;
}
.select-input.form-input {
  width: 100%;
  border-radius: 4px;
  border:1px solid #ddd;
}
.form-field input[type="radio"] {
  float: left;
  position: absolute;
  opacity: 0;
  width: auto;
}
.form-field input {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  font-size: 14px;
}
#step-1 {
  display: none;
}
#step-1 .form-field label {
  padding:10px 13px 10px 13px;
  cursor: pointer;
  background: #fff;
  display: flex;
  font-size: 18px;
  border-radius: 4px;
  flex-direction: row;
  border: 1px solid #e2e2e2;
  align-items: center;
  width: 100%;
  position: relative;
}
#step-2 {
  margin-top: 15px;
}
#step-2 .form-input {
  min-height: 45px;
}
.hidden {
  display: none;
}
#step-2 label {
  display: table;
  width: 100%;
  font-size: 14px;
  padding-left: 4px;
  padding-bottom: 4px;
  text-transform: uppercase;
  font-weight: lighter;
}
.brand-icon {
  position: absolute;
  z-index: 1;
  left: 14px;
  top: 13px;
  font-size: 21px;
}
.brand-icon.bullguard {

}

@font-face {
  font-family: KievitWebPro-Regular;
  font-weight: 400;
  font-style: normal;
  src: url(https://theme.zdassets.com/theme_assets/2187579/9b223aa5c89ecfe895d7030d2118b0bec654472e.eot?#iefix) format('embedded-opentype'),
  url(https://theme.zdassets.com/theme_assets/2187579/c7cc0250ffc01405fca2e30f2775a35b8cf2ee8d.woff2) format('woff2'),
  url(https://theme.zdassets.com/theme_assets/2187579/359c48e80c30fe2dd2827cdac0fc6064e7c7b681.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: KievitWebPro-Light;
  font-weight: 400;
  font-style: normal;
  src: url(https://theme.zdassets.com/theme_assets/2187579/76e26226aad8341726742d04414f2c7664f7695c.eot?#iefix) format('embedded-opentype'),
  url(https://theme.zdassets.com/theme_assets/2187579/ba298dda5a37008c2608b9d2b0908ac97afa76af.woff2) format('woff2'),
  url(https://theme.zdassets.com/theme_assets/2187579/45cfbc538c742b343b816c46c1599467be430e82.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: KievitWeb-Book;
  font-weight: 400;
  font-style: normal;
  src: url(https://theme.zdassets.com/theme_assets/2187579/54611971b9eff2bb5409fa8e175d0547416181d3.eot?#iefix) format('embedded-opentype'),
  url(https://theme.zdassets.com/theme_assets/2187579/36bfb49b71dfe473f830d78b6726efa725a08e0a.woff2) format('woff2'),
  url(https://theme.zdassets.com/theme_assets/2187579/49cfdb6a140d8c187cc36e523a72345b056f0ed9.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: KievitWeb-Bold;
  font-weight: 400;
  font-style: normal;
  src: url(https://theme.zdassets.com/theme_assets/2187579/38fd3b765c137fd166e8b805c4500a45ad85c80f.eot?#iefix) format('embedded-opentype'),
  url(https://theme.zdassets.com/theme_assets/2187579/3661b0b3233fe46c4bc81c5485fa15e0b795ed22.woff2) format('woff2'),
  url(https://theme.zdassets.com/theme_assets/2187579/a610f8649fb6ec0793b3a24f90523870f77b1192.woff) format('woff');
  font-display: swap;
}
@font-face{
  font-family:'bullguard_icons';
  src:url('../public/fonts/bullguard_icons.eot?9qi5px');
  src:url('../public/fonts/bullguard_icons.eot?9qi5px') format('embedded-opentype'),
  url('../public/fonts/bullguard_icons.ttf?9qi5px') format('truetype'),
  url('../public/fonts/bullguard_icons.woff?9qi5px') format('woff'),
  url('../public/fonts/bullguard_icons.svg?9qi5px') format('svg');
  font-weight:normal;
  font-style:normal;
  font-display:swap
}
[class^="icon-"],[class*=" icon-"]{
  font-family:'bullguard_icons' !important;
  speak:none;
  font-style:normal;
  font-weight:normal;
  font-variant:normal;
  text-transform:none;
  line-height:1;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale
}
.icon-logo:before {
  content: "";
}
/* Avira webfont */
@font-face {
  font-family: 'avira';
  src:  url('https://theme.zdassets.com/theme_assets/2187579/30f52fbca3011f6ca2c790efea209ff22f925822.eot?ymoujb');
  src:  url('https://theme.zdassets.com/theme_assets/2187579/30f52fbca3011f6ca2c790efea209ff22f925822.eot?ymoujb#iefix') format('embedded-opentype'),
  url('https://theme.zdassets.com/theme_assets/2187579/ad6a54ca581ad8b9496959112e860d017807170a.ttf?ymoujb') format('truetype'),
  url('https://theme.zdassets.com/theme_assets/2187579/25c652062bbeb33388dffc35b5108a4e81418c5b.woff?ymoujb') format('woff'),
  url('https://theme.zdassets.com/theme_assets/2187579/3bb8bdfb635f93997032c51acb1c555c5e8a48b7.svg?ymoujb#avira') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="iconav-"], [class*=" iconav-"],
.iconav-product:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'avira';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.iconav-support_icon_s:before {
  content: "\e953";
}
.iconav-question .path1:before {
  content: "\e950";
  color: #e51e25;
}
.iconav-question .path2:before {
  content: "\e951";
  margin-left: -1em;
  color: #e51e25;
}
.iconav-question .path3:before {
  content: "\e952";
  margin-left: -1em;
  color: #e51e25;
}
.iconav-angle-left:before {
  content: "\f104";
}
.iconav-angle-right:before {
  content: "\f105";
}
.iconav-angle-up:before {
  content: "\f106";
}
.iconav-angle-down:before {
  content: "\f107";
}
.iconav-globe:before {
  content: "\e94f";
}
.iconav-info:before {
  content: "\e94d";
}
.iconav-support:before {
  content: "\e94e";
}
.iconav-android-mobile:before {
  content: "\e945";
}
.iconav-info-sheet:before {
  content: "\e94b";
}
.iconav-QR-scanner-icon:before,
.category-id-360000184698 .iconav-product:before {
  content: "\e94c";
  color: #e51e25;
}
.iconav-win-mobile:before {
  content: "\e946";
}
.iconav-browser-mobile:before {
  content: "\e947";
}
.iconav-ios-mobile:before {
  content: "\e948";
}
.iconav-iot-mobile:before {
  content: "\e949";
}
.iconav-mac-mobile:before {
  content: "\e94a";
}
.iconav-iot-1:before {
  content: "\e91d";
  color: #e02228;
}
.iconav-blog:before {
  content: "\e91f";
}
.iconav-facebook:before {
  content: "\e920";
}
.iconav-linkedin:before {
  content: "\e922";
}
.iconav-pinterest:before {
  content: "\e926";
}
.iconav-twitter:before {
  content: "\e927";
}
.iconav-youtube:before {
  content: "\e944";
}
.iconav-safe-shopping:before,
.products-container .request_custom_fields_360001815318 .checkmark:before {
  content: "\e91e";
  color: #e60018;
}
.iconav-angle-left:before {
  content: "\f104";
}
.iconav-angle-right:before {
  content: "\f105";
}
.iconav-angle-up:before {
  content: "\f106";
}
.iconav-angle-down:before {
  content: "\f107";
}
.iconav-avira-logo .path1:before {
  content: "\e91b";
  color: rgb(219, 15, 22);
}
.iconav-avira-logo .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.blocks-item:hover span.iconav-right-arrow {
  color: #07c !important;
}
.iconav-right-arrow:before {
  content: "\e91a";
}

/*the container must be positioned relative:*/
.custom-select {
  position: relative;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-selected {
  padding: 3px 9px;
  cursor: pointer;
  background: #fff;
  user-select: none;
  border-radius: 4px;
  border: 1px solid #ddd;
  min-height: 44px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.select-items div {
  padding: 3px 9px;
  cursor: pointer;
  background: #fff;
  user-select: none;
  border-radius: 0;
  border-bottom: 1px solid #ddd;
  min-height: 44px;
  display: flex;
  justify-content: start;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  align-items: center;
}
.select-items div:hover {
  background-color: #eff5f9;
}

/*style items (options):*/
.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  padding-top: 3px;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}
.select-selected.select-arrow-active {
  outline: 2px solid #245FCC;
}
.select-items div:hover, .same-as-selected {
}

input.btn {
  background: #2A77CC;
  color: #fff;
  border: 1px solid #2A77CC;
  font-size: 20px;
  padding: 10px 25px;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;
}
input.btn:hover,
input.btn:focus,
input.btn:active {
  background: #034a97;
  border-color: #034a97;
}
p a {
  color: #2A77CC;
}
.form-field input {
  font-family: 'KievitWebPro-Regular', sans-serif;
}
form .err-txt{padding:2px 5px;margin-bottom: 15px;color:#f20;}
form .error{border-color:#f20;}
form .error .err-txt{display: block}

.wyswyg-editor {
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #e5e6e6;
  color: #222;
}

@media(max-width: 767px) {
  .page-main-content header {
      padding: 0 15px;
  }
  .page-main-content header h1 {
      text-align: center;
  }
  .d-flex.flex-justify-between {
      flex-direction: column;
  }
  .form-field.half {
      width: 100%;
  }
}

/* Just added */
.page-main-content header {
  padding: 0;
}
.form-field input {
  -webkit-appearance: none;
  outline: none;
}
#step-2 .form-input {
  min-height: inherit;
}
.form-field .form-input:focus {
  box-shadow: 0 0 0 1px rgb(38,132,255);
  border-color: rgb(38,132,255);
  -webkit-appearance: none;
}
.custom-select .select-input.form-input {
  border: 0;
}
input.btn[type="submit"] {
  font-family: KievitWebPro-Regular,sans-serif;
}
.wyswyg-editor {
  margin-bottom: 20px;
}
.svg {
  z-index: 1000; 
  top: -45px;
  position: relative;
  height: 0px; 
}