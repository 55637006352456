/** WebForm Generic Styles **/
body {
  background-color: #fff;
  color: #424242;
  font-family: "KievitWebPro-Regular", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}